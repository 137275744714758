import {
    apiRequest,
    overtimeYears,
    overtimeMonths,
    overtimeHistory,
} from "../../../../../services/adapters/process"

class IndexViewRepository {

    fetchYears = async () => {
        return await apiRequest("get", overtimeYears, {}, true)
    }

    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", overtimeMonths, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", overtimeHistory, {
            params: query
        }, true)
    }
}

export default IndexViewRepository