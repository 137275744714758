import { accessRole } from "../../../../helpers/access_role"
import classnames from "classnames"
import React, { Fragment, useRef, useState } from "react"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"


import PpkActiveListComponent from './Components/PpkActiveListComponent'
import PpkInactiveListComponent from './Components/PpkInactiveListComponent'

const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                    {
                                        (accessRole('create')) ?
                                            <Link to="/official-travel/ppk.html?create">
                                                <Button color="primary"><i className="uil-folder-medical"></i> Tambah Data Baru</Button>
                                            </Link>
                                            : null
                                    }
                                </div>
                                <CardTitle>Pejabat Pembuat Komitmen</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Pejabat Pembuat Komitmen seluruh Unit Kerja Kementerian Investasi/BKPM
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block" style={{ color: (activeTab === "1" ? '#5b73e8' : '#7b8190') }}>Aktif</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block" style={{ color: (activeTab === "2" ? '#5b73e8' : '#7b8190') }}>Tidak Aktif</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <PpkActiveListComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef}/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <PpkInactiveListComponent ref={inactiveRef} handleActiveSuccess={reloadActiveRef}/>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView