import { Subject } from 'rxjs'
import apiResponse from '../../../../../../services/apiResponse'
import IndexRepository from '../Repositories/IndexRepository'


class IndexBloc {

    repository = new IndexRepository()
    fetchIIPCChannel = new Subject();
    fetchDetailIIPCChannel = new Subject();
    fetchChangeIIPCChannel = new Subject();
    fetchCutiBesarChannel = new Subject();
    fetchDetailCutiBesarChannel = new Subject();
    fetchChangeCutiBesarChannel = new Subject();
    fetchTugasBelajarChannel = new Subject();
    fetchDetailTugasBelajarChannel = new Subject();
    fetchChangeTugasBelajarChannel = new Subject();
    fetchCLTNChannel = new Subject();
    fetchDetailCLTNChannel = new Subject();
    fetchChangeCLTNChannel = new Subject();
    fetchDokumenChannel = new Subject();
    fetchDetailDokumenChannel = new Subject();
    fetchChecklistDokumenChannel = new Subject();
   

    constructor() {
        this.fetchIIPCChannel.next({status: apiResponse.INITIAL})
        this.fetchDetailIIPCChannel.next({status: apiResponse.INITIAL})
        this.fetchChangeIIPCChannel.next({status: apiResponse.INITIAL})
        this.fetchCutiBesarChannel.next({status: apiResponse.INITIAL})
        this.fetchDetailCutiBesarChannel.next({status: apiResponse.INITIAL})
        this.fetchChangeCutiBesarChannel.next({status: apiResponse.INITIAL})
        this.fetchTugasBelajarChannel.next({status: apiResponse.INITIAL})
        this.fetchDetailTugasBelajarChannel.next({status: apiResponse.INITIAL})
        this.fetchChangeTugasBelajarChannel.next({status: apiResponse.INITIAL})
        this.fetchCLTNChannel.next({status: apiResponse.INITIAL})
        this.fetchDetailCLTNChannel.next({status: apiResponse.INITIAL})
        this.fetchChangeCLTNChannel.next({status: apiResponse.INITIAL})
        this.fetchDokumenChannel.next({status: apiResponse.INITIAL})
        this.fetchDetailDokumenChannel.next({status: apiResponse.INITIAL})
        this.fetchChecklistDokumenChannel.next({status: apiResponse.INITIAL})
    }

    fetchIIPC = async (query, cancelToken) => {
        this.fetchIIPCChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchIIPC(query, cancelToken).then((result) => {
                this.fetchIIPCChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchIIPCChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetailIIPC = async (query, cancelToken) => {
        this.fetchDetailIIPCChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailIIPC(query, cancelToken).then((result) => {
                this.fetchDetailIIPCChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDetailIIPCChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChangeIIPC = async (query, cancelToken) => {
        this.fetchChangeIIPCChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChangeIIPC(query, cancelToken).then((result) => {
                this.fetchChangeIIPCChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchChangeIIPCChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchTugasBelajar = async (query, cancelToken) => {
        this.fetchTugasBelajarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchTugasBelajar(query, cancelToken).then((result) => {
                this.fetchTugasBelajarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchTugasBelajarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetailTugasBelajar = async (query, cancelToken) => {
        this.fetchDetailTugasBelajarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailTugasBelajar(query, cancelToken).then((result) => {
                this.fetchDetailTugasBelajarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDetailTugasBelajarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChangeTugasBelajar = async (query, cancelToken) => {
        this.fetchChangeTugasBelajarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChangeTugasBelajar(query, cancelToken).then((result) => {
                this.fetchChangeTugasBelajarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchChangeTugasBelajarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCutiBesar = async (query, cancelToken) => {
        this.fetchCutiBesarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCutiBesar(query, cancelToken).then((result) => {
                this.fetchCutiBesarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchCutiBesarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetailCutiBesar = async (query, cancelToken) => {
        this.fetchDetailCutiBesarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailCutiBesar(query, cancelToken).then((result) => {
                this.fetchDetailCutiBesarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDetailCutiBesarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChangeCutiBesar = async (query, cancelToken) => {
        this.fetchChangeCutiBesarChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChangeCutiBesar(query, cancelToken).then((result) => {
                this.fetchChangeCutiBesarChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchChangeCutiBesarChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchCLTN = async (query, cancelToken) => {
        this.fetchCLTNChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCLTN(query, cancelToken).then((result) => {
                this.fetchCLTNChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchCLTNChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetailCLTN = async (query, cancelToken) => {
        this.fetchDetailCLTNChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailCLTN(query, cancelToken).then((result) => {
                this.fetchDetailCLTNChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDetailCLTNChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChangeCLTN = async (query, cancelToken) => {
        this.fetchChangeCLTNChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChangeCLTN(query, cancelToken).then((result) => {
                this.fetchChangeCLTNChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchChangeCLTNChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
    

    fetchDokumen = async (query, cancelToken) => {
        this.fetchDokumenChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDokumen(query, cancelToken).then((result) => {
                this.fetchDokumenChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDokumenChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchDetailDokumen = async (query, cancelToken) => {
        this.fetchDetailDokumenChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetailDokumen(query, cancelToken).then((result) => {
                this.fetchDetailDokumenChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchDetailDokumenChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }

    fetchChecklistDokumen = async (query, cancelToken) => {
        this.fetchChecklistDokumenChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchChecklistDokumen(query, cancelToken).then((result) => {
                this.fetchChecklistDokumenChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.fetchChecklistDokumenChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
    }
}

export default IndexBloc