import {
    apiRequest,
    tplnT14RegulerMonth,
    tplnT14Create,
    tplnT14Months,
    tplnT14History,
} from "../../../../../../services/adapters/process"

class IndexViewRepository {

    fetchRegulerMonts = async (query) => {
        return await apiRequest("get", tplnT14RegulerMonth, {
            params: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", tplnT14Create, {
            body: query
        }, true)
    }
    
    fetchMonths = async (query, cancelToken) => {
        return await apiRequest("get", tplnT14Months, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchHistory = async (query) => {
        return await apiRequest("get", tplnT14History, {
            params: query
        }, true)
    }
}

export default IndexViewRepository