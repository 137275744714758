import React, { Component } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Row,
    Col
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { Link } from "react-router-dom"

class AppBox extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showDrillDown: false
        }

        
    }

    componentDidMount() {

    }

    componentWillUnmount(){
       
    }
    
    render() {
        return (
            <>
                <Dropdown
                    className="dropdown d-inline-block"
                    isOpen={this.state.showDrillDown}
                    toggle={() => {
                        this.setState({
                            showDrillDown: !this.state.showDrillDown
                        })
                    }}
                >
                    <DropdownToggle
                        className="btn header-item noti-icon waves-effect"
                        tag="button"
                    >
                        <i className="uil-apps"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" right>
                        <div className="px-lg-2">
                            <Row className="g-0">
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Kepegawaian</span>
                                    </a>
                                </Col>
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Persuratan</span>
                                    </a>
                                </Col>
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Inventori</span>
                                    </a>
                                </Col>
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Sigap</span>
                                    </a>
                                </Col>
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Simantap</span>
                                    </a>
                                </Col>
                                <Col sm={4}>
                                    <a target='_blank' href="http://google.com" className="dropdown-icon-item" rel="noreferrer">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/LogoBKPM.png/1200px-LogoBKPM.png" alt="Github" />
                                        <span>Simapan</span>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </>
        )
    }
}

export default AppBox