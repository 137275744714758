import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import {
    Modal
} from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import IndexViewBloc from '../Blocs/IndexViewBloc'
import apiResponse from '../../../../../services/apiResponse'
import OvertimeHistoryComponent from './OvertimeHistoryComponent'
import OvertimeDownloadComponent from './OvertimeDownloadComponent'

class OvertimeIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc();

    source
    historyTable = 'historyPayrollOvertimeIndexListComponentx'
    defaultOrder = 'overtime_month'
    defaultSize = 20
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            showOvertimeHistory: false,
            tplnHistoryTitle: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => { }

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "overtime_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: '140',
            align: 'center',
            cell: record => {
                return (
                    <>
                        {record.overtime_status === 2 || record.overtime_status === 4 ?
                            <button
                                className="btn btn-soft-primary btn-sm"
                                onClick={() => {

                                }}
                                style={{
                                    padding: '2px 5px',
                                    borderRadius: 16
                                }}>
                                done
                            </button>
                            : <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: '2px 5px',
                                    borderRadius: 16
                                }}>
                                done
                            </button>}
                        {" "}
                        {record.overtime_status === 4 ?
                            <button
                                className="btn btn-soft-success btn-sm"
                                onClick={() => {

                                }}
                                style={{
                                    padding: '2px 5px',
                                    borderRadius: 16
                                }}>
                                approved
                            </button>
                            : <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: '2px 5px',
                                    borderRadius: 16
                                }}>
                                approved
                            </button>}
                    </>
                )
            }
        },
        {
            key: "overtime_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: record => {
                return (
                    <>
                        {record.overtime_month_name}
                    </>
                )
            }
        },
        {
            key: "overtime_employee_total",
            text: "Total Pegawai",
            className: "",
            align: 'center',
            width: '140',
            sortable: true
        },
        {
            key: "overtime_status_employee",
            text: "Simpeg",
            className: "",
            sortable: true,
            width: '100',
            align: 'center',
            cell: record => {
                var className = (record.overtime_status_employee === 1 ? 'bg-success' : 'bg-danger') + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            }
        },
        /* {
            key: "overtime_status_overtime",
            text: "ST",
            className: "",
            sortable: true,
            width: '100',
            align: 'center',
            cell: record => {
                var className = (record.overtime_status_overtime === 1 ? 'bg-success' : 'bg-danger') + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            }
        }, */
        {
            key: "overtime_status_presence",
            text: "Absensi",
            className: "",
            sortable: true,
            width: '100',
            align: 'center',
            cell: record => {
                var className = (record.overtime_status_presence === 1 ? 'bg-success' : 'bg-danger') + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            }
        },
        {
            key: "overtime_uuid",
            text: "Aksi",
            className: "",
            width: '140',
            align: 'center',
            cell: record => {
                //var dataReady = record.overtime_status_employee === 1 && record.overtime_status_overtime === 1 && record.overtime_status_presence === 1 ? (record.overtime_status === 1 || record.overtime_status === 3 ? true : false) : false
                var dataReady = record.overtime_status_employee === 1 && record.overtime_status_presence === 1 ? (record.overtime_status === 1 || record.overtime_status === 3 ? true : false) : false
                var dataGenerate = record.overtime_status === 2 ? true : false
                var dataReject = record.overtime_status === 3 ? true : false
                var dataDone = record.overtime_status === 4 ? true : false
                return (
                    <>
                        <span>
                            {accessRole('create') ?
                                <>
                                    {dataReady ?
                                        <Link
                                            className={"btn btn-" + (dataReject ? "danger" : "primary") + " btn-sm"}
                                            style={{
                                                marginRight: '2px',
                                                padding: '2px 5px'
                                            }}
                                            to={"/payroll/overtime.html?create&uuid=" + record.overtime_uuid}
                                        >
                                            <i className="uil-cog"></i>
                                        </Link>
                                        :
                                        <button
                                            className="btn btn-soft-secondary btn-sm" disabled={true}
                                            style={{
                                                marginRight: '2px',
                                                padding: '2px 5px'
                                            }}>
                                            <i className="uil-cog"></i>
                                        </button>
                                    }
                                </>
                                : null
                            }
                            <button
                                className="btn btn-warning btn-sm"
                                onClick={() => {
                                    this.setState({
                                        showOvertimeHistory: true,
                                        historyUuid: record.overtime_uuid,
                                        tplnHistoryTitle: record.overtime_month_name + " " + this.props.year
                                    });
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                <i className="uil-clock-eight"></i>
                            </button>
                            {accessRole('approve') ?
                                <>
                                    {dataGenerate ?
                                        <Link
                                            className={"btn btn-success btn-sm"}
                                            style={{
                                                marginRight: '2px',
                                                padding: '2px 5px'
                                            }}
                                            to={"/payroll/overtime.html?approve&uuid=" + record.overtime_uuid}
                                        >
                                            <i className="uil-check"></i>
                                        </Link>
                                        :
                                        <button
                                            className="btn btn-soft-secondary btn-sm" disabled={true}
                                            style={{
                                                marginRight: '2px',
                                                padding: '2px 5px'
                                            }}>
                                            <i className="uil-check"></i>
                                        </button>
                                    }
                                </>
                                : null
                            }
                            {accessRole('print') ?
                                <>
                                    {dataDone ?
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => {
                                                this.setState({
                                                    showOvertimeDownload: true,
                                                    downloadUuid: record.overtime_uuid
                                                });
                                            }}
                                            style={{
                                                padding: '2px 5px'
                                            }}
                                        >
                                            <>
                                                <i className="uil-arrow-to-bottom"></i>
                                                {/* <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret>
                                                        <i className="uil-arrow-to-bottom"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.downloadBloc.fetchDownloadDetail({uuid: record.overtime_uuid})
                                                            }}
                                                        >
                                                            Detail Isi
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.downloadBloc.fetchDownloadSsp({uuid: record.overtime_uuid})
                                                            }}
                                                        >
                                                            SSP
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                            </>
                                        </button>
                                        :
                                        <button
                                            className="btn btn-soft-secondary btn-sm" disabled={true}
                                            style={{
                                                padding: '2px 5px'
                                            }}>
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </>
                )
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={900}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showOvertimeHistory}
                    toggle={() => {
                        this.setState({
                            showOvertimeHistory: this.state.showOvertimeHistory
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Riwayat Proses Lembur {this.state.tplnHistoryTitle}
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showOvertimeHistory: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OvertimeHistoryComponent uuid={this.state.historyUuid} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showOvertimeDownload}
                    toggle={() => {
                        this.setState({
                            showOvertimeDownload: !this.state.showOvertimeDownload
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Unduh Berkas Lembur
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showOvertimeDownload: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <OvertimeDownloadComponent uuid={this.state.downloadUuid} />
                    </div>
                </Modal>
                <ConfirmAlert

                    loadingTitle="Membuat file..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="File akan segera terunduh otomatis"

                    failedTitle="Gagal!"
                    failedInfo="File gagal dibuat, silahkan coba lagi"

                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
            </>
        )
    }

}

export default OvertimeIndexListComponent