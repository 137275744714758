import { accessRole } from "../../../../../../helpers/access_role"
import { config } from "../../../../../../helpers/config_global"
import React, { Component } from "react"
import { Link } from "react-router-dom"

import { Tooltip, Modal } from "reactstrap"

import MKIDatatable from "../../../../../../modules/mki-datatable/lib"

import { cancelToken } from "../../../../../../services/adapters/base"

import IndexViewBloc from "../Blocs/IndexViewBloc"
import apiResponse from "../../../../../../services/apiResponse"
import TplnHistoryComponent from "./TplnHistoryComponent"
import TplnDownloadComponent from "./TplnDownloadComponent"

class TplnIndexListComponent extends Component {
    indexViewBloc = new IndexViewBloc()

    source
    historyTable = "historyPayrollTplnRegularIndexListComponent"
    defaultOrder = "tunkin_month"
    defaultSize = 20
    defaultSort = "desc"
    configDatatable = {
        ...config("datatable"),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort,
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            showTplnHistory: false,
            tplnHistoryTitle: null,
            tplnHistorySubTitle: null,

            tooltip: false,

            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable)
                ? JSON.parse(sessionStorage.getItem(this.historyTable))
                : {
                      filter_value: "",
                      page_number: 1,
                      page_size: this.defaultSize,
                      sort_order: {
                          column: this.defaultOrder,
                          order: this.defaultSort,
                      },
                  },
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip })

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number - 1) * this.state.filter.page_size
        if (this.state.defaultSize !== this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size,
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            year: this.props.year,
        }
        await this.indexViewBloc.fetchMonths(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel()
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {}

    componentDidMount() {
        this.setTokenAPI()

        this.indexViewBloc.monthsChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexViewBloc.monthsChannel.unsubscribe()
    }

    columns = [
        {
            key: "tunkin_status",
            text: "Proses",
            className: "",
            sortable: true,
            width: "190",
            align: "center",
            cell: (record) => {
                return (
                    <>
                        {record.tunkin_status === 2 || record.tunkin_status === 4 ? (
                            <button
                                className="btn btn-soft-primary btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                done
                            </button>
                        )}{" "}
                        {record.tunkin_status === 3 ? (
                            <button
                                className="btn btn-soft-danger btn-sm"
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                review
                            </button>
                        ) : (
                            <>
                                {record.tunkin_status === 4 ? (
                                    <button
                                        className="btn btn-soft-success btn-sm"
                                        onClick={() => {}}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        approved
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-soft-secondary btn-sm"
                                        disabled={true}
                                        style={{
                                            padding: "2px 5px",
                                            borderRadius: 16,
                                        }}
                                    >
                                        approval
                                    </button>
                                )}
                            </>
                        )}{" "}
                        {record.tunkin_spm_number !== null ? (
                            <button
                                className="btn btn-soft-primary btn-sm"
                                disabled={true}
                                onClick={() => {}}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                SPM
                            </button>
                        ) : (
                            <button
                                className="btn btn-soft-secondary btn-sm"
                                disabled={true}
                                style={{
                                    padding: "2px 5px",
                                    borderRadius: 16,
                                }}
                            >
                                SPM
                            </button>
                        )}
                    </>
                )
            },
        },
        {
            key: "tunkin_month",
            text: "Bulan",
            className: "",
            sortable: true,
            cell: (record) => {
                return <>{record.tunkin_month_name}</>
            },
        },
        {
            key: "tunkin_employee_total",
            text: "Total Pegawai",
            className: "",
            align: "center",
            width: "140",
            sortable: true,
        },
        {
            key: "tunkin_year_salary",
            text: "Tahun Acuan Gaji",
            className: "",
            align: "center",
            width: "170",
            sortable: true,
            cell: (record) => {
                return <>{record.tunkin_year_salary !== null ? record.tunkin_year_salary : "-"}</>
            },
        },
        {
            key: "tunkin_status_gpp",
            text: "GPP",
            className: "",
            sortable: true,
            width: "80",
            align: "center",
            cell: (record) => {
                var className = (record.tunkin_status_gpp === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunkin_status_employee",
            text: "Simpeg",
            className: "",
            sortable: true,
            width: "100",
            align: "center",
            cell: (record) => {
                var className = (record.tunkin_status_employee === 1 ? "bg-success" : "bg-danger") + " rounded-circle d-inline-block"
                return (
                    <>
                        <div className={className} style={{ height: 10, width: 10 }}></div>
                    </>
                )
            },
        },
        {
            key: "tunkin_uuid",
            text: "Aksi",
            className: "",
            width: "180",
            align: "center",
            cell: (record) => {
                var dataReady = record.tunkin_status_gpp === 1 && record.tunkin_status_employee === 1 ? (record.tunkin_status === 1 || record.tunkin_status === 3 ? true : false) : false
                var dataGenerate = record.tunkin_status === 2 ? true : false
                var dataDone = record.tunkin_status === 4 ? true : false
                const thisId = record.tunkin_id
                return (
                    <>
                        <span>
                            {accessRole("create") ? (
                                <>
                                    {dataReady ? (
                                        <>
                                            <Link
                                                className="btn btn-primary btn-sm"
                                                id={"create-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/tpln-reguler.html?create&uuid=" + record.tunkin_uuid}
                                            >
                                                <i className="uil-cog"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["create" + thisId]}
                                                target={"create-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["create" + thisId]: !this.state["create" + thisId],
                                                    })
                                                }}
                                            >
                                                Proses TPLN
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-cog"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            <button
                                className="btn btn-warning btn-sm"
                                id={"history-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        showTplnHistory: true,
                                        historyUuid: record.tunkin_uuid,
                                        tplnHistoryTitle: record.tunkin_month_name + " " + this.props.year,
                                        tplnHistorySubTitle: record.tunkin_date_name + " (" + (record.tunkin_percentage * 1).toFixed(2) * 1 + "% dari nilai tunjangan)",
                                    })
                                }}
                                style={{
                                    marginRight: "2px",
                                    padding: "2px 5px",
                                }}
                            >
                                <i className="uil-clock-eight"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2,
                                }}
                                placement="top"
                                isOpen={this.state["history" + thisId]}
                                target={"history-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["history" + thisId]: !this.state["history" + thisId],
                                    })
                                }}
                            >
                                Riwayat
                            </Tooltip>
                            {accessRole("approve") ? (
                                <>
                                    {dataGenerate ? (
                                        <>
                                            <Link
                                                className={"btn btn-success btn-sm"}
                                                id={"approve-" + thisId}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                                to={"/payroll/tpln-reguler.html?approve&uuid=" + record.tunkin_uuid}
                                            >
                                                <i className="uil-check"></i>
                                            </Link>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["approve" + thisId]}
                                                target={"approve-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["approve" + thisId]: !this.state["approve" + thisId],
                                                    })
                                                }}
                                            >
                                                Setujui TPLN
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-check"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("print") ? (
                                <>
                                    {dataDone ? (
                                        <>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                id={"print-" + thisId}
                                                onClick={() => {
                                                    this.setState({
                                                        showTplnDownload: true,
                                                        downloadUuid: record.tunkin_uuid,
                                                    })
                                                }}
                                                style={{
                                                    marginRight: "2px",
                                                    padding: "2px 5px",
                                                }}
                                            >
                                                <i className="uil-arrow-to-bottom"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["print" + thisId]}
                                                target={"print-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["print" + thisId]: !this.state["print" + thisId],
                                                    })
                                                }}
                                            >
                                                Download
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                marginRight: "2px",
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-arrow-to-bottom"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                            {accessRole("update") ? (
                                <>
                                    {dataDone ? (
                                        <>
                                            <button
                                                className="btn btn-success btn-sm"
                                                id={"spm-" + thisId}
                                                onClick={() => {
                                                    this.setState({})
                                                }}
                                                style={{
                                                    padding: "2px 5px",
                                                }}
                                            >
                                                <i className="uil-link"></i>
                                            </button>
                                            <Tooltip
                                                style={{
                                                    marginBottom: 2,
                                                }}
                                                placement="top"
                                                isOpen={this.state["spm" + thisId]}
                                                target={"spm-" + thisId}
                                                toggle={() => {
                                                    this.setState({
                                                        ["spm" + thisId]: !this.state["spm" + thisId],
                                                    })
                                                }}
                                            >
                                                SPM
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-soft-secondary btn-sm"
                                            disabled={true}
                                            style={{
                                                padding: "2px 5px",
                                            }}
                                        >
                                            <i className="uil-link"></i>
                                        </button>
                                    )}
                                </>
                            ) : null}
                        </span>
                    </>
                )
            },
        },
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1020}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState(
                            {
                                loadingTable: true,
                                filter: filter,
                            },
                            function () {
                                this.setTokenAPI()
                            }
                        )
                    }}
                    loading={this.state.loadingTable}
                />
                <Modal
                    size="md"
                    isOpen={this.state.showTplnHistory}
                    toggle={() => {
                        this.setState({
                            showTplnHistory: !this.state.showTplnHistory,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            <>
                                Riwayat Proses TPLN {this.state.tplnHistoryTitle}
                                {this.state.tplnHistorySubTitle !== null ? <div style={{ fontWeight: "normal", fontSize: 11 }}>Periode: {this.state.tplnHistorySubTitle}</div> : <></>}
                            </>
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTplnHistory: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TplnHistoryComponent uuid={this.state.historyUuid} />
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.showTplnDownload}
                    toggle={() => {
                        this.setState({
                            showTplnDownload: !this.state.showTplnDownload,
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            Unduh Berkas TPLN Reguler
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showTplnDownload: false,
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TplnDownloadComponent uuid={this.state.downloadUuid} />
                    </div>
                </Modal>
            </>
        )
    }
}

export default TplnIndexListComponent
